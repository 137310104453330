import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import SyncIcon from '@material-ui/icons/Sync';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import clsx from 'clsx';
import { debounce } from 'debounce';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { searchData, stableSort } from '../../../util/helpers/sorting';
import { formatTimeDate } from '../../../util/helpers/string';
import ConditionalWrapper from '../../utils/ConditionalWrapper';
import BadRequest from '../../widgets/BadRequest';
import LoadingCircle from '../../widgets/LoadingCircle';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';

const useStyles = makeStyles(({ spacing, container }) => ({
  root: {
    width: '100%',
  },
  container: { ...container(1), maxWidth: '100%' },
  table: {
    minWidth: 710,
  },
  tableWrapper: {
    overflowX: 'auto',
    borderRadius: 10,
    height: '100%',
  },
  feedbackMargin: {
    marginTop: spacing(4),
  },
  bottomToolbar: {
    padding: spacing(0, 1, 0, 1),
  },
  grow: {
    flexGrow: 1,
  },
  extraSelectorsFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing(2),
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const EnhancedTable = React.memo((props) => {
  const [order, setOrder] = useState(localStorage.getItem(`${props.tableId}order`) || 'asc');
  const [orderBy, setOrderBy] = useState(localStorage.getItem(`${props.tableId}orderBy`) || null);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchFilteredRows, setSearchFilteredRows] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(JSON.parse(localStorage.getItem(`${props.tableId}dense`)) || false);
  const [rowsPerPage, setRowsPerPage] = useState(JSON.parse(localStorage.getItem(`${props.tableId}rowsPerPage`)) || 10);

  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const handleChangeSaveState = (stateName, stateToBeSaved, setFun) => {
    localStorage.setItem(`${props.tableId}${stateName}`, stateToBeSaved);
    setFun(stateToBeSaved);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';

    localStorage.setItem(`${props.tableId}order`, newOrder);
    localStorage.setItem(`${props.tableId}orderBy`, property);

    setOrder(newOrder);
    setOrderBy(property);
  };

  const onSearchChange = (searchText) => {
    setSearchText(searchText);
    setPage(0);
  };

  const onSearchChangeDebounce = debounce(() => {
    if (searchText) {
      let { dataIsEmpty, rows, columns } = getTableData();
      if (!dataIsEmpty) {
        let data = searchData(rows, columns, searchText);
        setSearchFilteredRows(data);
      }
    }
  }, 300);

  const handleSelectAllClick = (event) => {
    let newSelected = [];
    let { dataIsEmpty, rows, columns } = getTableData();

    rows = searchText ? searchFilteredRows : rows;

    if (event.target.checked && !dataIsEmpty) {
      newSelected = rows.map((n) => n[columns[0].id]);
    }

    setSelected(newSelected);
  };

  const handleSelectClick = (event, firstColumnValue) => {
    let selectedIndex = selected.indexOf(firstColumnValue);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, firstColumnValue);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const getTableData = () => {
    if (!props.data) {
      return { dataIsEmpty: true };
    }

    let { rows, columns } = props.data;
    let dataIsEmpty = !(rows && rows.length && columns && rows.length);

    return { dataIsEmpty, rows, columns };
  };

  const renderBottomToolbarExtraSelectors = () => {
    const bottomSelectors = props.bottomToolbarProps && props.bottomToolbarProps.extraSelectors;
    return (
      (bottomSelectors &&
        bottomSelectors.length &&
        bottomSelectors.map((selector) => (
          <FormControlLabel control={selector.control} label={selector.label} key={`selector${selector.label}`} />
        ))) ||
      null
    );
  };

  const isSelected = (column) => selected.indexOf(column) !== -1;

  // Debounce search changes
  useEffect(onSearchChangeDebounce, [searchText, page]);
  // Clear selection whenever data changes
  useEffect(() => setSelected([]), [props.data]);

  const { clickAction, dataLoaded } = props;
  const { dataIsEmpty, rows, columns } = getTableData();

  const firstKey = columns && columns.length ? columns[0].id : null;

  let filteredRows = searchText ? searchFilteredRows : rows;
  let sortedRows = filteredRows ? stableSort(filteredRows, order, orderBy) : [];

  return (
    <div className={classes.root}>
      <ConditionalWrapper
        condition={!props.doNotContainerize}
        wrapper={(children) => <Container className={classes.container}>{children}</Container>}
      >
        {props.showTitle && (
          <Typography variant="h4" style={theme.text.title}>
            {props.title}
          </Typography>
        )}
        <EnhancedTableToolbar
          {...props.toolbarProps}
          selected={selected}
          rows={sortedRows}
          columns={columns}
          onSearchChanged={onSearchChange}
          searchText={searchText}
        />
        {dataLoaded ? (
          !dataIsEmpty ? (
            <>
              <div className={classes.tableWrapper}>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    selectedCount={selected.length}
                    rowsCount={filteredRows.length}
                    columns={columns}
                    showPicture={props.showPicture}
                  />
                  <TableBody>
                    {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const firstColumnValue = row[firstKey];
                      const isItemSelected = isSelected(firstColumnValue);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${firstColumnValue}${index}`}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox" onClick={(event) => handleSelectClick(event, firstColumnValue)}>
                            <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                          {props.showPicture && (
                            <TableCell
                              padding="none"
                              onClick={row.__hasPicture ? () => props.onPictureClick(row) : undefined}
                            >
                              {row.__hasPicture && (
                                <IconButton aria-label="viewImage" className={classes.margin}>
                                  <SearchIcon fontSize="medium" />
                                </IconButton>
                              )}
                            </TableCell>
                          )}
                          {columns.map((field, index) => {
                            // There exists a clickAction that may be aasociated with this column
                            let actionData = !!clickAction ? clickAction(row, field) : null;
                            // There is a clickAction associated with this column
                            let clickableColumn = !!clickAction && !!actionData;
                            // Whether the clickAction is a link or a button
                            let actionIsLink = clickableColumn && actionData.isLink;

                            const columnValue =
                              field.id === 'pushStatus' && !!row.pushStatus ? (
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: `domain.ps${row.pushStatus}`,
                                    defaultMessage: row.pushStatus,
                                  })}
                                >
                                  <IconButton
                                    aria-label="Push status"
                                    className={clsx(
                                      classes.margin,
                                      row.pushStatus !== 'FAILED' ? classes.disableRipple : undefined
                                    )}
                                    onClick={() => props.onTriggerSessionPushClick(row)}
                                    style={{
                                      color:
                                        row.pushStatus === 'FAILED'
                                          ? theme.palette.error.main
                                          : row.pushStatus === 'PENDING'
                                          ? theme.palette.info.main
                                          : row.pushStatus === 'PUSHED'
                                          ? theme.palette.success.main
                                          : theme.palette.warning.main,
                                    }}
                                  >
                                    {row.pushStatus === 'FAILED' ? (
                                      <SyncProblemIcon />
                                    ) : row.pushStatus === 'PENDING' ? (
                                      <SyncIcon />
                                    ) : row.pushStatus === 'PUSHED' ? (
                                      <CheckCircleIcon />
                                    ) : (
                                      <HelpOutlineIcon />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              ) : field.isDate ? (
                                formatTimeDate(row[field.id], intl, field.dateFormat)
                              ) : (
                                row[field.id]
                              );

                            const tableCellContent = (
                              <Box
                                component={clickableColumn ? (actionIsLink ? Link : Button) : 'span'}
                                to={actionIsLink ? actionData.getLinkPath(row, field) : undefined}
                                onClick={clickableColumn && !actionIsLink ? actionData.onClick : undefined}
                              >
                                {columnValue}
                              </Box>
                            );

                            return row[field.id] && field.id.length ? (
                              index === 0 ? (
                                <TableCell
                                  component="th"
                                  align="left"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  key={`${field.id}${index}`}
                                >
                                  {tableCellContent}
                                </TableCell>
                              ) : (
                                <TableCell align="right" key={`${field.id}${index}`}>
                                  {tableCellContent}
                                </TableCell>
                              )
                            ) : (
                              <TableCell key={`${field.id}${index}`} />
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Toolbar className={classes.bottomToolbar}>
                <FormControl component="fieldset">
                  <FormGroup className={classes.extraSelectorsFormGroup}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={dense}
                          onChange={(e) => handleChangeSaveState('dense', e.target.checked, setDense)}
                        />
                      }
                      label={intl.formatMessage({ id: 'et.dense' })}
                    />

                    {renderBottomToolbarExtraSelectors()}
                  </FormGroup>
                </FormControl>

                <div className={classes.grow} />

                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25, 50]}
                  component="div"
                  count={filteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => handleChangeSaveState('page', newPage, setPage)}
                  onRowsPerPageChange={(e) => {
                    const rowsPerPage = parseInt(e.target.value, 10);
                    handleChangeSaveState('rowsPerPage', rowsPerPage, setRowsPerPage);
                    setPage(0);
                  }}
                />
              </Toolbar>
            </>
          ) : (
            <div className={classes.feedbackMargin}>
              <BadRequest text={intl.formatMessage({ id: 'noData' })} extraView={props.missingDataAlternative} />
            </div>
          )
        ) : (
          <div className={classes.feedbackMargin}>
            <LoadingCircle />
          </div>
        )}
      </ConditionalWrapper>
    </div>
  );
});

EnhancedTable.defaultProps = {
  search: true,
};

EnhancedTable.propTypes = {
  data: PropTypes.object.isRequired,
  tableId: PropTypes.string.isRequired,
  toolbarProp: PropTypes.object,
  bottomToolbarProps: PropTypes.object,
  title: PropTypes.string,
  linkPath: PropTypes.string,
  linkState: PropTypes.object,
  missingDataAlternative: PropTypes.func,
};

export default EnhancedTable;
