import { firestore } from 'firebase';

export function separateCamelCase(text) {
  return (
    text.charAt(0).toUpperCase() +
    text
      .substr(1)
      .replace(/([A-Z])/g, ' $1')
      .trim()
  );
}

export function formatTimeDate(dateOrTimestamp, intl, dateFormat) {
  return intl.formatTime(
    dateOrTimestamp instanceof firestore.Timestamp ? dateOrTimestamp.toDate() : dateOrTimestamp,
    dateFormat ?? {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
}
